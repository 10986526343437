import React, { HTMLProps } from "react";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/abel"; // Agency FB

import { CBDNow, Navigation } from "./index";


const Card = ({ children }: HTMLProps<HTMLDivElement>) => (
    <div className="flex bg-white max-w-sm rounded-lg p-5 h-full">
      <div className="px-2">
        <StaticImage
          alt="Tear drop."
          src="../../../../images/cbd-now-tear.png"
          loading="eager"
          objectFit="cover"
          layout="fixed"
          width={20}
        />
      </div>
      <p>{ children }</p>
    </div>
);

const Cards = (): JSX.Element => (
  <div>
    <div className="flex flex-wrap justify-center space-y-3 items-center md:space-x-5 mt-10">
      <Card>Harry Brown is the founder and director of CBD Now. He has written books and articles about CBD and is one of the 100 most influential people in cannabis. He has built out educational content focusing on scientific information about cannabis and regularly speaks about cannabis science. He believes people should have an open mind about the use of CBD oil.</Card>
      <CBDNow />
    </div>
    <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 justify-items-center gap-4 mt-5">
      <Card>Chris Williams is CBD Now's chief science writer and a PhD candidate in political science. He has written articles about CBD oil for a variety of online science magazines.</Card>
      <Card>Susan Armstrong is advertising director for CBD Now. She has worked as a TV producer and writer. Susan believes in educating the public about medical cannabis.</Card>
      <Card>Kiana Evans has worked for an international corporation in the agriculture industry before coming to CBD Now.</Card>
    </div>
    <div className="flex flex-wrap justify-center space-y-4 items-center md:space-x-5 mt-4">
      <Card>Albert Ames is a consultant for CBD Now. He believes people should have an open mind about the use of CBD oil.</Card>
      <Card>Lamar Jenkins, DVM, serves as an advisor for CBD Now. He has been practicing veterinary medicine since 2012. He is certified in veterinary acupuncture.</Card>
    </div>
  </div>
);

const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 px-10 py-10 flex-grow">
      <h2 className="text-4xl w-full text-center">About CBD Now</h2>
      <Cards />
    </div>
  );
};

const CBDNowPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Abel" }}>
      <Navigation />
      <Body />
      <Navigation />
    </div>
  );
};

export default CBDNowPage;
